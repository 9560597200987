<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理> <b>项目详情</b></span>
            <div class="flex-center" @click="back">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="container">
            <div class="introduction-container">
                <div class="introduction-title">
                    <span>{{ $getTitleByType('会议', meetInfo.cid) }}简介</span>
                    <router-link v-if="isPublish" :to="'/home/add?id=' + id">
                        <el-button class="introduction-edit" type="primary">修改</el-button>
                    </router-link>
                </div>
                <div class="introduction-content">
                    <div class="introduction-content-time">
                        {{ meetInfo.true_name }}于{{ meetInfo.add_time }}创建
                    </div>
                    <div class="introduction-content-name">{{ meetInfo.name }}</div>
                    <div v-if="meetInfo.desc" class="introduction-content-desc">
                        <div :class="{more: meetInfo.more,nomore: !meetInfo.more}" style="white-space:pre-wrap;">
                            {{ meetInfo.desc }}
                        </div>
                        <span v-if="meetInfo.hasMore" @click="showMore()">{{ meetInfo.more ? '收起更多' : '查看更多' }}</span>
                    </div>
                    <div v-else class="introduction-content-desc">
                        备注： --
                    </div>
                    <div class="introduction-content-desc" v-if="meetInfo.bubijia">
                        无需提供三方比价原因： {{ meetInfo.bubijia}}
                    </div>
                    <div class="introduction-content-other">
                        <div>项目编号：{{ meetInfo.project_no }}</div>
                        <div>项目类别：{{ meetInfo.cat_name }}</div>
                    </div>
                    <div class="introduction-content-other">
                        <div>所属学会：{{ meetInfo.society.join() }}</div>
                        <div>{{ $getTitleByType('大会主席', meetInfo.cid) }}：{{ meetInfo.chairman }}</div>
                    </div>
                    <div class="introduction-content-other">
                        <div>{{ $getTitleByType('会议日期', meetInfo.cid) }}：{{ meetInfo.time }}</div>
                    </div>
                </div>
            </div>
            <div class="statistics-container">
                <div class="statistics-state">
                    <span>会议统计</span>
                    <div class="statistics-state-total">
                        <div class="state-total">
                            <div class="state-total-state">进行中</div>
                            <div class="state-total-num">{{ tongji.jxz }}</div>
                        </div>
                        <div class="state-total">
                            <div class="state-total-state">已完成</div>
                            <div class="state-total-num">{{ tongji.ywc }}</div>
                        </div>
                        <div class="state-total">
                            <div class="state-total-state">已撤销</div>
                            <div class="state-total-num">{{ tongji.ybh }}</div>
                        </div>
                    </div>
                </div>
                <div class="statistics-money">
                    <!--<el-button class="statistics-money-change" type="primary" @click="shoWEditMoney()">修改</el-button>-->
                    <div class="statistics-money-total">
                        <div class="money-total-container">
                            <i class="money-total-icon iconfont iconiconmoney"></i>
                            <div class="money-total">
                                <div class="money-total-state">拟到账金额</div>
                                <div class="money-total-num">
                                    {{ nidao }}<span class="money">万元</span>
                                </div>
                            </div>
                        </div>
                        <div class="money-total-container">
                            <i class="money-total-icon iconfont iconcontract2"></i>
                            <div class="money-total">
                                <div class="money-total-state">合同金额</div>
                                <div class="money-total-num">
                                    {{ hetong }}<span class="money">万元</span>
                                </div>
                            </div>
                        </div>
                        <div class="money-total-container">
                            <i class="money-total-icon iconfont iconanquan"></i>
                            <div class="money-total">
                                <div class="money-total-state">到账金额</div>
                                <div class="money-total-num">
                                    {{ daozhang }}<span class="money">万元</span>
                                </div>
                            </div>
                        </div>
                        <div class="money-total-container">
                            <i class="money-total-icon iconfont iconjine"></i>
                            <div class="money-total">
                                <div class="money-total-state">可用金额</div>
                                <div class="money-total-num">
                                    {{ keyong }}<span class="money">万元</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="moments-container">
                <span>会议动态</span>
                <div class="moments-content">
                    <div class="moments-item">
                        <div class="moments-item-title">招商资料 <i>v</i></div>
                        <div class="datum-list">
                            <div
                                v-for="(item, index) in shenpi.zhaoshang"
                                :key="index"
                                class="datum-item"
                            >
                                <el-button
                                    class="moments-content-detail"
                                    @click="toDetail(2, item.id)"
                                >查看详情
                                </el-button
                                >
                                <div class="datum-item-info">
                                    <div class="datum-item-info-title">{{ item.name }}</div>
                                    <div class="datum-item-info-desc">
                                        所属学会：{{ item.society_name }} / 企业名称：{{ item.corporate_name }}
                                        / 联系人：{{ item.contacts }} / 赞助金额：{{ parseFloat(Number(item.amount).toFixed(6))
                                        }}万元
                                    </div>
                                    <div class="datum-item-info-desc">
                                        申请人：{{ item.true_name }} / 申请时间：{{ item.add_time }}
                                        / 当前状态：
                                        <!--                    0审批中 1已完成   2已上传附件 3已驳回 4已撤销-->
                                        <span v-if="item.state === 0"
                                        >审核中</span>
                                        <span v-if="item.state === 1">已完成</span>
                                        <span v-if="item.state === 3">已驳回</span>
                                        <span v-if="item.state === 4">已撤销</span>
                                    </div>
                                </div>
                                <div
                                    v-for="(file, index) in item.files"
                                    :key="index"
                                    class="datum-item-file"
                                >
                                    <div class="datum-item-file-name">
                                        <i class="iconfont iconpdf"></i>{{ file.fileName }}
                                    </div>
                                    <a
                                        :href="file.filePath | filePath"
                                        class="datum-item-file-preview"
                                        target="_blank"
                                    >预览</a
                                    >
                                    <a
                                        :href="file.filePath"
                                        class="datum-item-file-down"
                                        download=""
                                        target="_blank"
                                    >下载</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">上游协议 <i>v</i></div>
                        <div class="datum-list">
                            <div
                                v-for="(item, index) in shenpi.zanzhu"
                                :key="index"
                                class="datum-item"
                            >
                                <el-button
                                    class="moments-content-detail"
                                    @click="toDetail(3, item.id)"
                                >查看详情
                                </el-button
                                >
                                <div class="datum-item-info">
                                    <div class="datum-item-info-title">{{ item.name }}</div>
                                    <div class="datum-item-info-desc">
                                        企业名称：{{ item.corporate_name }} / 合同金额：{{
                                            parseFloat(Number(item.amount).toFixed(6))
                                        }}万元 <br/>
                                        企业备注：{{ item.corporate_name_remarks }}<br/>
                                        申请人：{{ item.true_name }} / 申请时间：{{ item.add_time }}
                                        /
                                        <!--                    0审批中 1已通过 2已上传附件 3已驳回 4已完成 5已撤销-->
                                        <span v-if="item.state === 0"
                                        >审批中</span
                                        >
                                        <span v-if="item.state === 1">上传盖章协议中</span>
                                        <span v-if="item.state === 2">存档中</span>
                                        <span v-if="item.state === 3">已驳回</span>
                                        <span v-if="item.state === 4">已完成</span>
                                        <span v-if="item.state === 6">企业负责人签字中</span>
                                        <span v-if="item.state === 5">已撤销</span>
                                    </div>
                                </div>
                                <div
                                    v-for="(file, index) in item.files"
                                    :key="index"
                                    class="datum-item-file"
                                >
                                    <div class="datum-item-file-name">
                                        <i class="iconfont iconpdf"></i>{{ file.fileName }}
                                    </div>
                                    <a
                                        :href="file.filePath | filePath"
                                        class="datum-item-file-preview"
                                        target="_blank"
                                    >预览</a
                                    >
                                    <a
                                        :href="file.filePath"
                                        class="datum-item-file-down"
                                        download=""
                                        target="_blank"
                                    >下载</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">到款情况 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title">
                                    <div>到款金额</div>
                                    <div>到款时间</div>
                                    <div>企业名称</div>
                                    <div>当前状态</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.daokuan"
                                    :key="index"
                                    class="datum-list-money-item"
                                >
                                    <div>{{ parseFloat(Number(item.amount).toFixed(6)) }}万元</div>
                                    <div>{{ item.add_time }}</div>
                                    <div>{{ item.corporate_name }}</div>
                                    <div>{{ item.state === 1 ? "已通知" : "通知中" }}</div>
                                    <div class="operate">
                                        <div @click="toDetail(4, item.id)">查看详情</div>
                                    </div>
                                </div>
                                <div class="datum-list-money-total">
                                    合计到款：{{ daokuanTotal }}万元
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">发票 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title">
                                    <div>开票金额</div>
                                    <div>企业名称</div>
                                    <div>申请时间</div>
                                    <div>当前状态</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.fapiao"
                                    :key="index"
                                    class="datum-list-money-item"
                                >
                                    <div>{{ parseFloat(Number(item.amount).toFixed(6)) }}万元</div>
                                    <div>{{ item.invoice_title }}</div>
                                    <div>{{ item.add_time }}</div>
                                    <div>{{ item.state === 6 ? "已开票" : "开票中" }}</div>
                                    <div class="operate">
                                        <div @click="toDetail(5, item.id)">查看详情</div>
                                    </div>
                                </div>
                                <div class="datum-list-money-total">
                                    合计到款：{{ fapiaoTotal }}万元
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">供应商比价 <i>v</i></div>
                        <div class="datum-list">
                            <div
                                v-for="(item, index) in shenpi.enterpriseEvaluation"
                                :key="index"
                                class="datum-item">
                                <el-button
                                    class="moments-content-detail"
                                    @click="toDetail(24, item.id)">查看详情
                                </el-button>
                                <div class="datum-item-info">
                                    <div class="datum-item-info-title">{{ item.name }}</div>
                                    <div class="datum-item-info-desc">
                                        供应商：{{ item.enterprise_name }} / 委托项目：{{ item.payment }}<br/>
                                    </div>
                                </div>
                                <div v-for="(file, index) in item.files"
                                     :key="index"
                                     class="datum-item-file">
                                    <div class="datum-item-file-name">
                                        <i class="iconfont iconpdf"></i>{{ file.fileName }}
                                    </div>
                                    <a :href="file.filePath | filePath"
                                       class="datum-item-file-preview"
                                       target="_blank">预览</a>
                                    <a :href="file.filePath"
                                       class="datum-item-file-down"
                                       download=""
                                       target="_blank">下载</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">下游协议 <i>v</i></div>
                        <div class="datum-list">
                            <div
                                v-for="(item, index) in shenpi.hetong"
                                :key="index"
                                class="datum-item"
                            >
                                <el-button
                                    class="moments-content-detail"
                                    @click="toDetail(6, item.id)"
                                >查看详情
                                </el-button
                                >
                                <div class="datum-item-info">
                                    <div class="datum-item-info-title">{{ item.name }}</div>
                                    <div class="datum-item-info-desc">
                                        供应商名称：{{ item.corporate_name }} / 合同金额：{{
                                            parseFloat(Number(item.amount).toFixed(6))
                                        }}万元 <br/>
                                        供应商备注：{{ item.corporate_name_remarks }}<br/>
                                        申请人：{{ item.true_name }} / 申请时间：{{ item.add_time }}
                                        /
                                        <!--  0审核中 1已完成 3已驳回  4已撤销-->
                                        <span v-if="item.state === 0"
                                        >审批中</span
                                        >
                                        <span v-if="item.state === 1">已完成</span>
                                        <span v-if="item.state === 3">已驳回</span>
                                        <span v-if="item.state === 4">已撤销</span>
                                    </div>
                                </div>
                                <div
                                    v-for="(file, index) in item.files"
                                    :key="index"
                                    class="datum-item-file"
                                >
                                    <div class="datum-item-file-name">
                                        <i class="iconfont iconpdf"></i>{{ file.fileName }}
                                    </div>
                                    <a
                                        :href="file.filePath | filePath"
                                        class="datum-item-file-preview"
                                        target="_blank"
                                    >预览</a
                                    >
                                    <a
                                        :href="file.filePath"
                                        class="datum-item-file-down"
                                        download=""
                                        target="_blank"
                                    >下载</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">特殊下游协议 <i>v</i></div>
                        <div class="datum-list">
                            <div
                                v-for="(item, index) in shenpi.tshetong"
                                :key="index"
                                class="datum-item"
                            >
                                <el-button
                                    class="moments-content-detail"
                                    @click="toDetail(17, item.id)"
                                >查看详情
                                </el-button
                                >
                                <div class="datum-item-info">
                                    <div class="datum-item-info-title">{{ item.name }}</div>
                                    <div class="datum-item-info-desc">
                                        企业名称：{{ item.corporate_name }} / 合同金额：{{
                                            parseFloat(Number(item.amount).toFixed(6))
                                        }}万元 <br/>
                                        申请人：{{ item.true_name }} / 申请时间：{{ item.add_time }}
                                        /
                                        <!--  0审核中 1已完成 3已驳回  4已撤销-->
                                        <span v-if="item.state === 0"
                                        >审批中</span
                                        >
                                        <span v-if="item.state === 1">已完成</span>
                                        <span v-if="item.state === 3">已驳回</span>
                                        <span v-if="item.state === 4">已撤销</span>
                                    </div>
                                </div>
                                <div
                                    v-for="(file, index) in item.files"
                                    :key="index"
                                    class="datum-item-file"
                                >
                                    <div class="datum-item-file-name">
                                        <i class="iconfont iconpdf"></i>{{ file.fileName }}
                                    </div>
                                    <a
                                        :href="file.filePath | filePath"
                                        class="datum-item-file-preview"
                                        target="_blank"
                                    >预览</a
                                    >
                                    <a
                                        :href="file.filePath"
                                        class="datum-item-file-down"
                                        download=""
                                        target="_blank"
                                    >下载</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">项目付款 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title">
                                    <div>付款金额</div>
                                    <div>付款项目</div>
                                    <div>申请时间</div>
                                    <div>当前状态</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.fukuan"
                                    :key="index"
                                    class="datum-list-money-item"
                                >
                                    <div>{{ parseFloat(Number(item.pay_amount).toFixed(6)) }}万元</div>
                                    <div>{{ item.pay_item }}</div>
                                    <div>{{ item.add_time }}</div>
                                    <div>{{ item.state === 6 ? "已付款" : "付款中" }}</div>
                                    <div class="operate">
                                        <div @click="toDetail(9, item.id)">查看详情</div>
                                    </div>
                                </div>
                                <div class="datum-list-money-total">
                                    合计到款：{{ fukuanTotal }}万元
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">劳务付款 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title">
                                    <div>付款金额</div>
                                    <div>付款项目</div>
                                    <div>申请时间</div>
                                    <div>当前状态</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.labourServices"
                                    :key="index"
                                    class="datum-list-money-item"
                                >
                                    <div>{{ parseFloat(Number(item.pay_amount).toFixed(6)) }}万元</div>
                                    <div>{{ item.pay_item }}</div>
                                    <div>{{ item.add_time }}</div>
                                    <div>{{ item.state === 6 ? "已付款" : "付款中" }}</div>
                                    <div class="operate">
                                        <div @click="toDetail(22, item.id)">查看详情</div>
                                    </div>
                                </div>
                                <div class="datum-list-money-total">
                                    合计到款：{{ labourTotal }}万元
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">退款 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title">
                                    <div>付款金额</div>
                                    <div>付款项目</div>
                                    <div>申请时间</div>
                                    <div>当前状态</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.tuikuanData"
                                    :key="index"
                                    class="datum-list-money-item"
                                >
                                    <div>{{ parseFloat(Number(item.pay_amount).toFixed(6)) }}万元</div>
                                    <div>{{ item.pay_item }}</div>
                                    <div>{{ item.add_time }}</div>
                                    <div>{{ item.state === 6 ? "已付款" : "付款中" }}</div>
                                    <div class="operate">
                                        <div @click="toDetail(23, item.id)">查看详情</div>
                                    </div>
                                </div>
                                <div class="datum-list-money-total">
                                    合计到款：{{ tuikuanTotal }}万元
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">支票申请 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title">
                                    <div>付款金额</div>
                                    <div>付款事由</div>
                                    <div>审批时间</div>
                                    <div>当前状态</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.teshu"
                                    :key="index"
                                    class="datum-list-money-item"
                                >
                                    <div>{{ parseFloat(Number(item.amount).toFixed(6)) }}万元</div>
                                    <div>{{ item.shiyou }}</div>
                                    <div>{{ item.sp_time }}</div>
                                    <div>{{ item.state === 5 ? "已付款" : "付款中" }}</div>
                                    <div class="operate">
                                        <div @click="toDetail(7, item.id)">查看详情</div>
                                    </div>
                                </div>
                                <div class="datum-list-money-total">
                                    合计到款：{{ teshuTotal }}万元
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">结项 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title active">
                                    <div>申请时间</div>
                                    <div>备注</div>
                                    <div>当前状态</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.jiexiang"
                                    :key="index"
                                    class="datum-list-money-item active"
                                >
                                    <div>{{ item.add_time }}</div>
                                    <div>{{ item.remarks ? item.remark : '--' }}</div>
                                    <div>{{ item.state === 6 ? "已结项" : "结项中" }}</div>
                                    <div class="operate">
                                        <div @click="toDetail(8, item.id)">查看详情</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">专项结项 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title active">
                                    <div>结算单位</div>
                                    <div>申请时间</div>
                                    <div>当前状态</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.teshuEnd"
                                    :key="index"
                                    class="datum-list-money-item active"
                                >
                                    <div>{{ item.list_name }}</div>
                                    <div>{{ item.add_time }}</div>
                                    <div>{{ item.state === 6 ? "已结项" : "结项中" }}</div>
                                    <div class="operate">
                                        <div @click="toDetail(13, item.id)">查看详情</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">特殊审批 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title active">
                                    <div>招商函的企业</div>
                                    <div>申请时间</div>
                                    <div>审批内容</div>
                                    <div>当前状态</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.contract"
                                    :key="index"
                                    class="datum-list-money-item active"
                                >
                                    <div>{{ item.corporate_name ? item.corporate_name : '无' }}</div>
                                    <div>{{ item.add_time }}</div>
                                    <el-tooltip class="item" effect="dark" :disabled="item.shenpi_content.length < 10" :content="item.shenpi_content" placement="top">
                                        <div class="ovHide" style="cursor: pointer">{{ item.shenpi_content }}</div>
                                    </el-tooltip>
                                    <div v-if="item.state === 0">审批中</div>
                                    <div v-if="item.state === 1">已完成</div>
                                    <div v-if="item.state === 2">已完成</div>
                                    <div v-if="item.state === 3">已驳回</div>
                                    <div v-if="item.state === 4">已撤销</div>
                                    <div v-if="item.state === 5">等待用印登记</div>
                                    <div class="operate">
                                        <div @click="toDetail(14, item.id)">查看详情</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">特殊签批 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title active">
                                    <div>申请时间</div>
                                    <div>当前状态</div>
                                    <div>签批内容</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.specialEndorsement"
                                    :key="index"
                                    class="datum-list-money-item active">
                                    <div>{{ item.add_time }}</div>
                                    <div v-if="item.state === 0">签字中</div>
                                    <div v-if="item.state === 1">已完成</div>
                                    <el-tooltip class="item" effect="dark" :disabled="item.content.length < 10" :content="item.content" placement="top">
                                        <div class="ovHide" style="cursor: pointer">{{ item.content }}</div>
                                    </el-tooltip>
                                    <div class="operate">
                                        <div @click="toDetail(21, item.id)">查看详情</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moments-item">
                        <div class="moments-item-title">垫付申请 <i>v</i></div>
                        <div class="datum-list">
                            <div class="datum-item">
                                <div class="datum-list-money-title active">
                                    <div>申请内容</div>
                                    <div>申请时间</div>
                                    <div>当前状态</div>
                                    <div class="operate">操作</div>
                                </div>
                                <div
                                    v-for="(item, index) in shenpi.advance  "
                                    :key="index"
                                    class="datum-list-money-item active"
                                >
                                    <div>{{ item.content }}</div>
                                    <div>{{ item.add_time }}</div>
                                    <div v-if="item.state === 0">签字中</div>
                                    <div v-if="item.state === 1">已完成</div>
                                    <div v-if="item.state === 2">已完成</div>
                                    <div v-if="item.state === 3">已驳回</div>
                                    <div v-if="item.state === 4">已撤销</div>
                                    <div v-if="item.state === 5">审批中</div>
                                    <div class="operate">
                                        <div @click="toDetail(15, item.id)">查看详情</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible="editVisible"
            center
            width="700px"
        >
            <span slot="title" class="dialog-title">修改金额</span>
            <div class="dialog-container flex-center">
                <div>拟到账金额</div>
                <el-input v-model="editMoney1" placeholder="请输入"></el-input>
                <span>万元</span>
            </div>
            <div class="dialog-container flex-center">
                <div>合同金额</div>
                <el-input v-model="editMoney2" placeholder="请输入"></el-input>
                <span>万元</span>
            </div>
            <div class="dialog-container flex-center">
                <div>到账金额</div>
                <el-input v-model="editMoney3" placeholder="请输入"></el-input>
                <span>万元</span>
            </div>
            <div class="dialog-container flex-center">
                <div>可用金额</div>
                <el-input v-model="editMoney4" placeholder="请输入"></el-input>
                <span>万元</span>
            </div>
            <div v-if="moneyList.length > 0" class="dialog-list">
                <h3>修改记录</h3>
                <p v-for="(item, index) in moneyList" :key="index">
          <span>{{ item.name }}</span
          >于<span>{{ item.add_time }}</span
                >修改：<br/>拟到账金额：<span>{{ item.nidao }}万元</span
                >，合同金额：<span>{{ item.hetong }}万元</span>，到账金额<span
                >{{ item.daozhang }}万元</span
                >， 可用金额：<span>{{ item.keyong }}万元</span>
                </p>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import {getTitleByType, toDetail, webUrl} from "../../tools";

export default {
    components: {ElButton},
    name: "detail",
    data() {
        return {
            id: "",
            projectInfo: {},

            editVisible: false,
            editMoney1: 0,
            editMoney2: 0,
            editMoney3: 0,
            editMoney4: 0,
            meetInfo: {
                cid: '',
                true_name: "",
                add_time: "",
                name: "",
                desc: "",
                project_no: "",
                cat_name: "",
                society: "",
                chairman: "",
            },
            tongji: {
                jxz: 0,
                ybh: 0,
                ywc: 0,
            },
            nidao: 0,
            hetong: 0,
            daozhang: 0,
            keyong: 0,
            shenpi: {
                daokuan: [],
                fapiao: [],
                hetong: [],
                teshu: [],
                zanzhu: [],
                fukuan: [],
                jiexiang: [],
                zhaoshang: [],
            },
            moneyList: [],
            daokuanTotal: 0,
            fapiaoTotal: 0,
            fukuanTotal: 0,
            labourTotal: 0,
            tuikuanTotal: 0,
            teshuTotal: 0,
            jiexiangTotal: 0,
            isPublish: false,
        };
    },
    created() {
        this.$emit("setIndex", [1, 1]);
        this.id = this.$route.query.id;
        this.getDetail();
        this.getFund();
    },
    methods: {
        showMore() {
            this.meetInfo.more = !this.meetInfo.more
        },
        /*去项目详情*/
        toDetail(type, id) {
            toDetail({oid: id, type: type})
            // switch (type) {
            //     case 1:
            //         this.$router.push({name: "approval", query: {id: id}});
            //         break;
            //     case 2:
            //         this.$router.push({name: "merchants", query: {id: id}});
            //         break;
            //     case 3:
            //         this.$router.push({name: "sponsor", query: {id: id}});
            //         break;
            //     case 4:
            //         this.$router.push({name: "payment", query: {id: id}});
            //         break;
            //     case 5:
            //         this.$router.push({name: "invoice", query: {id: id}});
            //         break;
            //     case 6:
            //         this.$router.push({name: "contract", query: {id: id}});
            //         break;
            //     case 17:
            //         this.$router.push({name: "scontract", query: {id: id}});
            //         break;
            //     case 7:
            //         this.$router.push({name: "otherPay", query: {id: id}});
            //         break;
            //     case 8:
            //         this.$router.push({name: "fpr", query: {id: id}});
            //         break;
            //     case 9:
            //         this.$router.push({name: "pay", query: {id: id}});
            //         break;
            //     case 13:
            //         this.$router.push({name: "otherFprDetail", query: {id: id}});
            //         break;
            //     case 14:
            //         this.$router.push({name: "otherApprovalDetail", query: {id: id}});
            //         break;
            //     case 15:
            //         this.$router.push({name: "advanceDetail", query: {id: id}});
            //         break;
            //     default:
            //         return "结项";
            // }
        },
        //  修改金额记录
        getFund() {
            this.$api.apiContent
                .getFund({
                    id: this.id,
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    page: 1,
                    limit: 5,
                    sign: this.$tools.SHA256(
                        {
                            id: this.id,
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            page: 1,
                            limit: 5,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.moneyList = res.data;
                    this.moneyList.map((item) => {
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000),
                            1
                        );
                    });
                })
                .catch();
        },
        //  确定修改金额
        submit() {
            this.$api.apiContent
                .setFund({
                    id: this.id,
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    nidao: this.editMoney1,
                    hetong: this.editMoney2,
                    daozhang: this.editMoney3,
                    keyong: this.editMoney4,
                    sign: this.$tools.SHA256(
                        {
                            id: this.id,
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            nidao: this.editMoney1,
                            hetong: this.editMoney2,
                            daozhang: this.editMoney3,
                            keyong: this.editMoney4,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then(() => {
                    this.getDetail();
                    this.$message.success("修改成功");
                    this.closeDialog();
                    this.getFund();
                })
                .catch((e) => {
                    this.$message.success(e.msg);
                });
        },
        closeDialog() {
            this.editVisible = !this.editVisible;
            this.editMoney1 = 0;
            this.editMoney2 = 0;
            this.editMoney3 = 0;
            this.editMoney4 = 0;
        },
        //  修改金额
        shoWEditMoney() {
            this.editVisible = true;
            this.editMoney1 = this.nidao;
            this.editMoney2 = this.hetong;
            this.editMoney3 = this.daozhang;
            this.editMoney4 = this.keyong;
        },
        //  获取项目基本信息
        getDetail() {
            this.$api.apiContent
                .getProjectDetail({
                    id: this.id,
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            id: this.id,
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {

                    this.isPublish = res.data.admin_id === this.$store.state.user.mid;
                    let desc = (res.data.desc ? ('备注:' + res.data.desc + '\n') : '') + (res.data.name_list_count > 0 ? (`${getTitleByType('会议名称', res.data.cid)}曾为:` + res.data.name_list.map(name => {
                            return name.last_name
                        }).join(',') + '   已修改' + res.data.name_list_count
                        + '次\n') : '') + (res.data.sco_list_count > 0 ? ('会议曾属学会:' + res.data.sco_list.map(name => {
                            return name.last_sid.join('、')
                        }).join(',') + '  已修改' + res.data.sco_list_count
                        + '次\n') : '') + (res.data.time_list_count > 0 ? (`${getTitleByType('会议日期', res.data.cid)}曾为:` + res.data.time_list.map(time => {
                        return this.$tools.ZHDate(new Date(time.last_start_time * 1000)) + '至' + this.$tools.ZHDate(new Date(time.last_end_time * 1000))
                    }).join(',') + '  已修改' + res.data.time_list_count + '次') : '')
                    this.meetInfo = {
                        true_name: res.data.true_name,
                        add_time: this.$tools.ZHDate(new Date(res.data.add_time * 1000), 6),
                        name: res.data.name,
                        desc: desc,
                        hasMore: true,
                        more: false,
                        project_no: res.data.pro_no.join(","),
                        cat_name: res.data.cat_name,
                        cid: res.data.cid,
                        society: res.data.society,
                        chairman: res.data.chairman,
                        bubijia: res.data.bubijia,
                        time:
                            this.$tools.ZHDate(new Date(res.data.start_time * 1000)) +
                            "至" +
                            this.$tools.ZHDate(new Date(res.data.end_time * 1000)),
                    };
                    this.tongji = res.data.tongji;
                    this.nidao = res.data.total.sponsorship_meet;
                    this.hetong = res.data.total.signing_meet;
                    this.daozhang = res.data.total.received_meet;
                    this.keyong = res.data.total.available_meet;
                    this.shenpi = res.data.shenpi;
                    for (let key in this.shenpi) {
                        this.shenpi[key].map((item) => {
                            item.add_time = this.$tools.ZHDate(
                                new Date(item.add_time * 1000)
                            );
                            item.sp_time = this.$tools.ZHDate(new Date(item.sp_time * 1000));
                        });
                    }
                    this.shenpi.daokuan.map((item) => {
                        this.daokuanTotal += item.amount * 1;
                    });
                    this.daokuanTotal = parseFloat(this.daokuanTotal.toFixed(6));

                    this.shenpi.fapiao.map((item) => {
                        this.fapiaoTotal += item.amount * 1;
                    });
                    this.fapiaoTotal = parseFloat(this.fapiaoTotal.toFixed(6));

                    this.shenpi.fukuan.map((item) => {
                        this.fukuanTotal += item.pay_amount * 1;
                    });
                    this.fukuanTotal = parseFloat(this.fukuanTotal.toFixed(6));

                    this.shenpi.labourServices.map((item) => {
                        this.labourTotal += item.pay_amount * 1;
                    });
                    this.labourTotal = parseFloat(this.labourTotal.toFixed(6));

                    this.shenpi.tuikuanData.map((item) => {
                        this.tuikuanTotal += item.pay_amount * 1;
                    });
                    this.tuikuanTotal = parseFloat(this.tuikuanTotal.toFixed(6));

                    this.shenpi.teshu.map((item) => {
                        this.teshuTotal += item.amount * 1;
                    });
                    this.teshuTotal = parseFloat(this.teshuTotal.toFixed(6));
                    this.shenpi.jiexiang.map((item) => {
                        this.jiexiangTotal += item.pay_amount * 1;
                    });
                    this.jiexiangTotal = parseFloat(this.jiexiangTotal.toFixed(6));
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        //  返回
        back() {
            this.$router.go(-1);
        },
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .container {
        width: 100%;
        min-height: 800px;

        .introduction-container {
            background-color: #ffffff;
            border-radius: 6px;
            padding: 26px 17px 0 26px;

            .introduction-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .introduction-edit {
                    width: 118px;
                    height: 40px;
                }

                span {
                    position: relative;
                    color: #000;
                    font-size: 24px;
                    margin-left: 17px;

                    &:before {
                        position: absolute;
                        background: #3b77e7;
                        border-radius: 2px;
                        width: 7px;
                        height: 20px;
                        top: 50%;
                        transform: translate(0, -50%);
                        left: -15px;
                        content: "";
                    }
                }
            }

            .introduction-content {
                margin-top: 21px;
                margin-left: 20px;
                margin-right: 20px;

                .introduction-content-time {
                    color: #999999;
                    font-size: 14px;
                }

                .introduction-content-name {
                    color: #333333;
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 32px;
                    margin-top: 12px;
                }

                .introduction-content-desc {
                    color: #666;
                    font-size: 14px;

                    .nomore {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }

                    .more {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        -webkit-line-clamp: 100;
                    }

                    span {
                        display: inline-block;
                        color: #3b77e7;
                        cursor: pointer;
                    }

                    margin-top: 18px;
                    margin-bottom: 18px;
                }

                .introduction-content-other {
                    display: flex;
                    flex-direction: row;
                    border-top: 1px solid #f1f1f1;
                    padding: 20px 0;
                    color: #333333;
                    font-size: 16px;

                    div {
                        flex: 1;
                        position: relative;
                        padding-left: 30px;

                        &:before {
                            position: absolute;
                            background: #d7d7d7;
                            border-radius: 10px;
                            width: 10px;
                            height: 10px;
                            top: 50%;
                            transform: translate(0, -50%);
                            left: 10px;
                            content: "";
                        }
                    }
                }
            }
        }

        .statistics-container {
            display: flex;
            flex-direction: row;
            background-color: #ffffff;
            border-radius: 6px;
            padding: 26px;
            margin-top: 20px;

            .statistics-state {
                width: 37%;
                border-right: 2px solid #f2f3f5;

                span {
                    position: relative;
                    color: #000;
                    font-size: 24px;
                    margin-left: 17px;

                    &:before {
                        position: absolute;
                        background: #3b77e7;
                        border-radius: 2px;
                        width: 7px;
                        height: 20px;
                        top: 50%;
                        transform: translate(0, -50%);
                        left: -15px;
                        content: "";
                    }
                }

                .statistics-state-total {
                    display: flex;
                    flex-direction: row;
                    padding: 30px;

                    .state-total {
                        flex: 1;
                        padding: 0 30px;

                        .state-total-state {
                            position: relative;
                            color: #333333;
                            font-size: 16px;

                            &:before {
                                position: absolute;
                                background: #3b77e7;
                                border-radius: 10px;
                                width: 10px;
                                height: 10px;
                                top: 50%;
                                transform: translate(0, -50%);
                                left: -15px;
                                content: "";
                            }
                        }

                        .state-total-num {
                            color: #333333;
                            font-size: 32px;
                            font-weight: bold;
                            margin-top: 21px;
                        }
                    }
                }
            }

            .statistics-money {
                width: 63%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .statistics-money-change {
                    align-self: flex-end;
                    width: 118px;
                    height: 40px;
                }

                .statistics-money-total {
                    display: flex;
                    justify-content: space-around;
                    flex-direction: row;
                    padding: 25px 0;

                    .money-total-container {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .money-total-icon {
                            display: block;
                            color: #ffffff;
                            font-size: 24px;
                            background: #3b77e7;
                            width: 48px;
                            height: 48px;
                            text-align: center;
                            line-height: 48px;
                            border-radius: 48px;
                        }

                        .money-total {
                            margin-left: 12px;

                            .money-total-state {
                                color: #333333;
                                font-size: 16px;
                            }

                            .money-total-num {
                                color: #333333;
                                font-size: 32px;
                                font-weight: bold;
                                margin-top: 10px;

                                .money {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .moments-container {
            background-color: #ffffff;
            border-radius: 6px;
            padding: 26px;
            margin-top: 20px;

            > span {
                position: relative;
                color: #000;
                font-size: 24px;
                margin-left: 17px;

                &:before {
                    position: absolute;
                    background: #3b77e7;
                    border-radius: 2px;
                    width: 7px;
                    height: 20px;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: -15px;
                    content: "";
                }
            }

            .moments-content {
                padding: 48px 48px 0 48px;

                .moments-item {
                    display: flex;
                    flex-direction: row;

                    .datum-list {
                        padding-bottom: 30px;
                        width: 90%;
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        .moments-content-detail {
                            position: absolute;
                            width: 101px;
                            height: 36px;
                            color: #3b77e7;
                            background: transparent;
                            border-radius: 4px;
                            border: solid 1px #3b77e7;
                            top: 50px;
                            right: 37px;
                            z-index: 10;
                            padding: 0;
                        }

                        .datum-item {
                            position: relative;
                            margin-left: 50px;
                            border-radius: 10px;
                            background: #f2f3f5;
                            margin-top: 10px;
                            z-index: 1;

                            .datum-list-money-total {
                                font-size: 16px;
                                color: #333333;
                                font-weight: bold;
                                padding: 25px;
                            }

                            .datum-list-money-title {
                                padding: 25px;
                                display: flex;
                                flex-direction: row;
                                color: #333333;
                                font-size: 16px;
                                border-bottom: 1px solid #e8e8e8;

                                div {
                                    width: 22%;

                                }

                                .operate {
                                    width: 13%;
                                }
                            }

                            .datum-list-money-item {
                                padding: 10px 25px;
                                display: flex;
                                flex-direction: row;
                                color: #333333;

                                div {

                                    width: 22%;
                                    font-size: 16px;
                                    padding-right: 5px;
                                    text-align: justify;
                                }

                                .operate {
                                    width: 13%;
                                    font-size: 16px;
                                    color: #3b77e7;

                                    > div {
                                        width: 100%;
                                        cursor: pointer;
                                    }
                                }
                            }

                            &:after {
                                position: absolute;
                                background: #f2f3f5;
                                transform: rotate(45deg);
                                width: 24px;
                                height: 24px;
                                top: 25px;
                                left: -10px;
                                content: "";
                                z-index: -1;
                            }

                            .datum-item-file {
                                display: flex;
                                padding: 10px 25px;

                                .datum-item-file-name {
                                    color: #333333;
                                    font-size: 14px;
                                    max-width: 500px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    line-height: 36px;

                                    i {
                                        color: #3b77e7;
                                        font-size: 20px;
                                        margin-right: 10px;
                                    }
                                }

                                a.datum-item-file-preview {
                                    width: 50px;
                                    height: 36px;
                                    line-height: 36px;
                                    text-align: center;
                                    font-size: 12px;
                                    border-radius: 3px;
                                    color: #ffffff;
                                    background-color: #3b77e7;
                                    margin-left: 20px;
                                }

                                a.datum-item-file-down {
                                    width: 50px;
                                    height: 36px;
                                    line-height: 36px;
                                    text-align: center;
                                    font-size: 12px;
                                    border-radius: 3px;
                                    color: #ffffff;
                                    background: #ff9727;
                                    margin-left: 20px;
                                }
                            }

                            .datum-item-info {
                                padding: 35px 25px 20px 25px;
                                border-bottom: 1px solid #e8e8e8;

                                .datum-item-info-title {
                                    font-size: 20px;
                                    line-height: 120%;
                                    font-weight: bold;
                                    color: #333333;
                                }

                                .datum-item-info-desc {
                                    font-size: 16px;
                                    color: #333333;
                                    margin-top: 21px;
                                    line-height: 150%;

                                    span {
                                        color: #ff9727;
                                    }
                                }
                            }
                        }
                    }

                    .moments-item-title {
                        position: relative;
                        width: 10%;
                        color: #3b77e7;
                        font-size: 20px;
                        font-weight: bold;
                        margin-top: 25px;

                        &:after {
                            position: absolute;
                            background: #3b77e7;
                            border-radius: 13px;
                            width: 13px;
                            height: 13px;
                            top: 3px;
                            right: -15px;
                            content: "";
                        }

                        &:before {
                            position: absolute;
                            background: #3b77e7;
                            border-radius: 13px;
                            width: 3px;
                            height: 100%;
                            top: 3px;
                            right: -10px;
                            content: "";
                            opacity: 0.5;
                        }

                        i {
                            position: absolute;
                            bottom: -10px;
                            right: -14px;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
}

.dialog-title {
    font-size: 26px;
}

.dialog-container {
    margin-bottom: 20px;

    div {
        width: 100px;
        flex-shrink: 0;
        font-size: 18px;
        color: #333333;
    }

    .el-input {
        flex: 1;
        margin: 0 20px;
        font-size: 16px;
    }
}

.dialog-list {
    h3 {
        font-size: 16px;
        line-height: 32px;
    }

    p {
        font-size: 14px;
        color: #666;
        line-height: 32px;

        span {
            color: #3b77e7;
        }
    }
}

</style>
